import { GlobalStyle } from "./globalStyles";
import { lazy, Suspense } from "react";
import {Helmet} from "react-helmet";
import React from "react";
const Home = lazy(() => import("./Pages/Home"));
const Header = lazy(() => import("./components/Header/index"));
const Footer = lazy(() => import("./components/Footer/index"));
const ScrollToTop = lazy(() => import("./components/ScrollToTop/index"));

function App() {
  return (
    <>
       <Helmet>
        <title>SMARTGALAXY</title>
        <meta name="agence de communication digitale" content="SMART GALAXY est une agence de communication digitale spécialisée dans la création des sites web,marketing digital et sécurité informatiques" />
        <meta name="keywords" content="SMARTGALAXY,agence,marketing digital,sponsoring,site web,tunisie"/>
  
    </Helmet>
      <Suspense fallback={null}>
        <GlobalStyle />
        {/* Hi There! */}
        <ScrollToTop />
        <Header />
        <Home />
        <Footer />
      </Suspense>
    </>
  );
}

export default App;
